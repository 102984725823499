<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Keresés"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="employeeCollection"
          :search="search"
          :sort-by="['fullName']"
          @click:row="handleClickItem"
          :loading="loadingTable"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <EmployeeModalForm
                :modalData="modalData"
                :userGroups="userGroupCollection"
                :permissions="permissions"
                :statuses="statuses"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></EmployeeModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="statusFilter"
                    :items="statusCollection"
                    item-text="value"
                    item-value="key"
                    @change="fetchHolidayRequest"
                  ></v-select>
                </th>
              </tr>
            </thead>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmployeeModalForm, { initialFormData } from "./EmployeeModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
export const ENDPOINT = "humanResources/employee/";
export const PERMISSION_TO = "humanResources.employee.";

export default {
  name: "Employees",
  components: {
    EmployeeModalForm,
    DeleteModalDialog,
  },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "employees",
      routePath: "/humanResource/employees/",
      permissionTo: PERMISSION_TO,

      cardTitle: this.$t("MENU.EMPLOYEES"),
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("MENU.EMPLOYEE"),
          value: "fullName",
        },
        {
          text: "Email cím",
          value: "email",
        },
        {
          text: "Adóazonosító",
          value: "tax_identification_number",
        },
        {
          text: "Status",
          value: "status",
          width: "200px",
          filter: (value) => {
            if (this.statusFilter == "Minden státusz") return true;
            return value == this.statusFilter;
          },
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),
      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
      statusFilter: "Minden státusz",
      statusCollection: ["Minden státusz", "Inaktív", "Aktív"],
    };
  },
  computed: {
    ...mapGetters(["employeeCollection", "userGroupCollection", "statuses"]),
  },
  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        this.dialogHours = false;
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["fetchEmployee", "fetchUserGroups"]),
    fetchModel() {
      this.fetchUserGroups();
      return this.fetchEmployee();
    },
    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.fullName = null;
      this.fetchModel();

      if (this.$route.name !== this.routeName)
        this.$router.push({ name: this.routeName });
    },
    handleSaveModalForm() {
      this.handleCloseModalForm();
      /*if (item) {
            this.handleClickItem(item);
          }*/
    },
  },
  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
