<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.EMPLOYEE") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.system.user"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="3" sm="3" md="3">
              <v-checkbox
                v-model="formModel.login"
                label=" Bejelentkezhet"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="formModel.surname"
                label="Vezetéknév"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="messages['surname']"
                @keyup="messages['surname'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="formModel.christian_name1"
                label="Keresztnév"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="messages['christian_name1']"
                @keyup="messages['christian_name1'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="formModel.christian_name2"
                label="Harmadik név"
                :id="dynamicID"
                :error-messages="messages['christian_name2']"
                @keyup="messages['christian_name2'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="formModel.email"
                label="Email"
                :rules="emailRules"
                :id="dynamicID"
                :error-messages="messages['email']"
                @keyup="messages['email'] = ''"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" sm="3" md="3">
              <div>
                <label for="example-input">Születés dátuma</label>
                <b-input-group class="mb-3">
                  <b-form-input
                    id="example-input"
                    v-model="selectedDate"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="selectedDate"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onContext"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <small v-if="messages['birth_date']" style="color: red">
                  {{ messages["birth_date"] }}
                </small>
              </div>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="formModel.tax_identification_number"
                label="Adóazonosító jel"
                :id="dynamicID"
                :error-messages="messages['tax_identification_number']"
                @keyup="messages['tax_identification_number'] = ''"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="formModel.szep_card_account_number"
                label="SZÉP kártya számlaszám"
                :id="dynamicID"
                :error-messages="messages['szep_card_account_number']"
                @keyup="messages['szep_card_account_number'] = ''"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="3" sm="3" md="3">
              <v-select
                v-model="formModel.user_group_id"
                :items="userGroups"
                label="Szabadság engedélyező csoport"
                item-text="groupName"
                item-value="id"
                :rules="userGroupRules"
                :id="dynamicID"
              >
              </v-select>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                v-model="formModel.comment"
                label="Megjegyzés"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./Employees";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

//const TRANSLATED_ATRIBUTES = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  translations: [],
  locale: "hu",
  login: 1,
  contact_data: [],
  email: null,
  surname: null,
  christian_name1: null,
  christian_name2: null,
  birth_date: null,
  portal: false,
  newsletter: false,
  comment: null,
  user_group_id: null,
  tax_identification_number: null,
  szep_card_account_number: null,
  messages: {},
  full_name: "",
});

export default {
  name: "EmployeeModalForm",
  props: ["modalData", "permissions", "userGroups"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (!!v && v.length < 255) || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      selectedDate: null,
    };
  },
  computed: {
    ...mapGetters(["statuses"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? "Edit - " + this.full_name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.EMPLOYEE");
    },

    /*translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },*/
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              data.surname = data.user.surname;
              data.christian_name1 = data.user.christian_name1;
              data.christian_name2 = data.user.christian_name2;
              data.fullName = data.surname + " " + data.christian_name1;
              this.full_name = data.surname + " " + data.christian_name1;
              if (data.christian_name2)
                data.fullName += " " + data.christian_name2;

              this.full_name += data.christian_name2
                ? " " + data.christian_name2
                : "";
              data.status = data.user.status;
              data.email = data.user.email;
              data.locale = data.user.locale;
              data.login = data.user.login;
              this.formModel = Object.assign({}, data);
              this.selectedDate = this.formModel.birth_date;
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.selectedDate = this.formModel.birth_date;
          //this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;
        this.formModel.birth_date = this.selectedDate;
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      // The following will be an empty string until a valid date is entered
      // console.log(ctx);
      this.formModel.birth_date = ctx.selectedYMD;
    },
  },

  mounted() {
    //this.setTranslatedAttributes();
  },
};
</script>
